<script setup lang="ts">
import consola from 'consola'

interface Props {
  as?: string
  siteKey?: string
  size?: string
  theme?: string
  // hl?: string
  loadingTimeout?: number
  type?: 'checkbox' | 'script'
}

const props = withDefaults(defineProps<Props>(), {
  siteKey: '6LemG-YmAAAAAALAEaca6Cq7Jw2O2Db8y2i_5Hnr',
  size: 'normal',
  theme: 'light',
  loadingTimeout: 0,
  type: 'checkbox',
  as: 'div',
})

const emit = defineEmits({
  'getValidateCode': (response: string) => response,
  'error': (reason: any) => reason,
  'expire': null,
  'fail': null,
  'update:modelValue': (response: string) => response,
})

const authCode = ref<string>('')

enum LocaleEnums {
  en = 'en',
  tw = 'zh-HK',
}
const scriptId = 'recaptcha-script'

const { locale } = useI18n()

const hl = computed<LocaleEnums>(() => {
  return LocaleEnums[locale.value as keyof typeof LocaleEnums] || LocaleEnums.tw
})

const recaptchaDiv = ref<HTMLDivElement>()
let recaptcha: any = null

defineExpose({
  execute() {
    window.grecaptcha.execute(recaptcha)
  },
  reset() {
    window.grecaptcha.reset()
  },
})

function onclick() {
  recaptcha ? window.grecaptcha.execute(recaptcha) : renderRecaptcha()
  // todo
}

function renderRecaptcha() {
  recaptcha = window.grecaptcha.render(recaptchaDiv.value, {
    'sitekey': props.siteKey,
    'theme': props.theme,
    'size': props.size,
    'callback': (response: string) => {
      authCode.value = response
      emit('update:modelValue', response)
      emit('getValidateCode', response)
    },
    'expired-callback': () => emit('expire'),
    'error-callback': () => emit('fail'),
  })
}

onMounted(() => {
  if (window.grecaptcha == null) {
    new Promise<void>((resolve, reject) => {
      let loadingCountdown: ReturnType<typeof setTimeout>
      let responded = false

      window.recaptchaReady = function () {
        if (responded)
          return
        responded = true
        clearTimeout(loadingCountdown)
        resolve()
      }

      const loadingFailed = (reason: string) => {
        return () => {
          if (responded)
            return
          responded = true
          clearTimeout(loadingCountdown)
          document.getElementById(scriptId)?.remove()
          reject(reason)
        }
      }

      if (props.loadingTimeout > 0)
        loadingCountdown = setTimeout(loadingFailed('timeout'), props.loadingTimeout)

      const doc = window.document
      const scriptTag = doc.createElement('script')
      scriptTag.id = scriptId
      scriptTag.onerror = loadingFailed('error')
      scriptTag.onabort = loadingFailed('aborted')
      scriptTag.setAttribute(
        'src',
        `https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit&hl=${hl.value}`,
      )
      doc.head.appendChild(scriptTag)
    })
      .then(() => {
        if (props.type === 'checkbox') {
          renderRecaptcha()
        }
      })
      .catch((err) => {
        emit('error', err)
      })
  }
  else {
    if (props.type === 'checkbox') {
      renderRecaptcha()
    }
  }
})

onBeforeUnmount(() => {
  // remove the script tag if the component is unmounted
  recaptcha = null
  window.recaptchaReady = () => void 0
  window.grecaptcha = null
  document.getElementById(scriptId)?.remove()
})
</script>

<template>
  <div v-if="props.type === 'checkbox'" ref="recaptchaDiv" />
  <component :is="props.as" v-else @click="onclick">
    <slot />
    <div ref="recaptchaDiv" class="absolute -top-20" />
  </component>
</template>
